<template>
  <b-overlay :show="isBusy" rounded="sm" variant="white" opacity="0.9">
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-card-title class="mb-2">
            <b-badge variant="primary" class="mr-25">
              {{ currentBookings.length }}
            </b-badge>
            <span>
              {{ currentBookings.length === 1 ? "Reserva" : "Reserves" }} en
              curs
            </span>
          </b-card-title>
        </b-col>
        <b-col v-if="currentBookings.length" cols="12" class="mb-2">
          <b-form-input
            v-model="filter"
            type="search"
            :placeholder="`${$t('Cerca')}...`"
            debounce="1000"
            :autofocus="!isMobile"
            :disabled="isBusy"
            class="w-full"
          />
        </b-col>
      </b-row>

      <b-table
        v-if="currentBookings.length || isBusy"
        striped
        borderless
        responsive
        class="position-relative mb-0"
        :busy.sync="isBusy"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        sort-icon-left
        :items="mappedCurrentBookings"
        :fields="fields"
        show-empty
        :filter="filter"
        :empty-text="$t('errors.emptyTable.title')"
        :empty-filtered-text="$t('errors.emptyTable.title')"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2" />
            <strong>{{ $t("Carregant") }}...</strong>
          </div>
        </template>

        <!-- Column: localizator -->
        <template #cell(localizator)="data">
          <b-booking-localizator
            :localizator="data.value"
            :source="data.item.source"
            :highlighted="data.item.highlighted"
            :fictitious="data.item.fictitious"
          />
        </template>

        <!-- Column: checkout -->
        <template #cell(checkoutOrder)="data">
          <div class="mb-25">
            <b-accommodation
              :uuid="data.item.accommodationUuid"
              :name="data.item.accommodation"
            />
          </div>
          <small class="d-flex align-items-center flex-nowrap">
            {{ data.item.checkin }}
            <feather-icon icon="ArrowRightIcon" size="10" class="mx-25" />
            {{ data.item.checkout }}
          </small>
          <small class="text-nowrap">
            <i>
              {{ data.item.client }}
            </i>
          </small>
        </template>
      </b-table>

      <div v-else class="text-center">
        <h5 class="py-2">Tohom és a casa seva, take it easy 😎 ✌️</h5>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BTable,
  BSpinner,
  BCardTitle,
  BBadge,
  BFormInput,
} from "bootstrap-vue";
import { notifyError } from "@/utils/methods";
import {
  formatDateObjectToDatabaseDate,
  formatDateObjectToDate,
  formatDateStringToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";
import BAccommodation from "@foravila-core/components/b-accommodation/BAccommodation.vue";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BTable,
    BSpinner,
    BBookingLocalizator,
    BAccommodation,
    BCardTitle,
    BBadge,
    BFormInput,
  },
  data() {
    return {
      isBusy: false,
      sortBy: "checkoutOrder",
      sortDesc: false,
      filter: null,
      fields: [
        {
          key: "localizator",
          label: this.$t("Reserva"),
          thStyle: { width: "180px" },
        },
        { key: "checkoutOrder", label: this.$t("Estada"), sortable: true },
      ],
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters["app/isMobile"];
    },
    currentBookings() {
      return this.$store.getters["bookings/currentBookings"];
    },
    mappedCurrentBookings() {
      if (!this.currentBookings.length) return [];
      return this.currentBookings.map((booking) => {
        let rowVariant = null;
        if (this.isSameAsToday(booking.checkout)) rowVariant = "danger";
        if (this.isSameAsToday(booking.checkin)) rowVariant = "primary";

        return {
          uuid: booking.uuid || this.$t("No definit"),
          date: new Date(booking.date) || this.$t("No definit"),
          localizator: booking.localizator || this.$t("No definit"),
          source: booking.source || this.$t("No definit"),
          accommodation: booking.accommodation?.name || this.$t("No definit"),
          accommodationUuid: booking.accommodation?.uuid || null,
          client: booking.client?.fullName || this.$t("No definit"),
          checkinOrder: booking.checkin || this.$t("No definit"),
          checkin:
            formatDateStringToDate(booking.checkin) || this.$t("No definit"),
          checkoutOrder: booking.checkout || this.$t("No definit"),
          checkout:
            formatDateStringToDate(booking.checkout) || this.$t("No definit"),
          highlighted: booking.highlighted || false,
          _rowVariant: rowVariant,
        };
      });
    },
  },
  created() {
    if (!this.currentBookings?.length) this.fetchCurrentBookings();
  },
  methods: {
    fetchCurrentBookings() {
      this.isBusy = true;
      this.$store
        .dispatch("bookings/fetchCurrentBookings")
        .catch(() =>
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.description")
          )
        )
        .finally(() => {
          this.isBusy = false;
        });
    },
    bookingFormattedDate(date) {
      return formatDateObjectToDate(date) || "No definit";
    },
    isSameAsToday(dateString) {
      const date = formatDateStringToDatabaseDate(dateString);
      const today = formatDateObjectToDatabaseDate(new Date());
      return date === today;
    },
  },
};
</script>
