<template>
  <b-overlay :show="loading" rounded="sm" variant="white" opacity="0.9">
    <b-card>
      <b-row class="mb-2">
        <b-col cols="12">
          <b-card-title class="text-nowrap mb-0">
            <span>⚠️ Incidències en curs</span>
            <b-badge
              v-if="activeIncidences.length"
              variant="primary"
              class="ml-50"
            >
              {{ activeIncidences.length }}
            </b-badge>
          </b-card-title>
        </b-col>
      </b-row>

      <template v-if="activeIncidences.length || loading">
        <incidences-mobile-list
          v-if="isMobile"
          :incidences="mappedActiveIncidences"
        />
        <b-table
          v-else
          striped
          borderless
          responsive
          hover
          class="position-relative"
          :busy.sync="isBusy"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          sort-icon-left
          :items="mappedActiveIncidences"
          :fields="fields"
          show-empty
          :empty-text="$t('errors.emptyTable.title')"
          @row-clicked="onRowClicked"
          @row-middle-clicked="onRowMiddleClicked"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-2" />
              <strong>{{ $t("Carregant") }}...</strong>
            </div>
          </template>

          <!-- Column: bookingLocalizator -->
          <template #cell(bookingLocalizator)="data">
            <b-booking-localizator
              :localizator="data.item.bookingLocalizator"
              :source="data.item.bookingSource"
              :highlighted="data.item.bookingHighlighted"
              :fictitious="data.item.bookingFictitious"
            />
          </template>

          <!-- Column: status -->
          <template #cell(status)="data">
            <b-badge
              :variant="getBookingIncidenceStatusColor(data.item.status)"
            >
              {{
                getBookingIncidenceStatusName(data.item.status, $i18n.locale)
              }}
            </b-badge>
          </template>

          <!-- Column: checkout -->
          <template #cell(checkoutOrder)="data">
            <div class="mb-25">
              <b-accommodation
                :uuid="data.item.accommodationUuid"
                :name="data.item.accommodation"
              />
            </div>
            <small class="d-flex align-items-center flex-nowrap mb-25">
              {{ data.item.checkin }}
              <feather-icon icon="ArrowRightIcon" size="10" class="mx-25" />
              {{ data.item.checkout }}
            </small>
            <small class="text-nowrap">
              <i>
                {{ data.item.client }}
              </i>
            </small>
          </template>

          <!-- Column: createdAtOrder -->
          <template #cell(createdAtOrder)="data">
            {{ getIncidenceDateTime(data.item.createdAt) }}
          </template>
        </b-table>
      </template>

      <div v-else class="text-center">
        <h5 class="py-2">
          No hi ha cap incidència en marxa, això s'ha de celebrar! 🙌 🎉
        </h5>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BTable,
  BSpinner,
  BCardTitle,
  BBadge,
} from "bootstrap-vue";
import {
  formatDateStringToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";
import BAccommodation from "@foravila-core/components/b-accommodation/BAccommodation.vue";
import {
  getBookingIncidenceStatusColor,
  getBookingIncidenceStatusName,
  notifyError,
} from "@/utils/methods";
import IncidencesMobileList from "@/views/incidences/list/components/IncidencesMobileList.vue";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BTable,
    BSpinner,
    BBookingLocalizator,
    BAccommodation,
    BCardTitle,
    BBadge,
    IncidencesMobileList,
  },
  data() {
    return {
      isBusy: false,
      sortBy: "checkinOrder",
      sortDesc: false,
      fields: [
        {
          key: "createdAtOrder",
          label: this.$t("Data d'incidència"),
          sortable: true,
          thStyle: { width: "170px" },
        },
        { key: "title", label: this.$t("Incidència") },
        { key: "status", label: this.$t("Estat"), sortable: true },
        {
          key: "bookingLocalizator",
          label: this.$t("Reserva"),
          thStyle: { width: "180px" },
        },
        { key: "checkoutOrder", label: this.$t("Estada"), sortable: true },
      ],
      getBookingIncidenceStatusColor,
      getBookingIncidenceStatusName,
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters["app/isMobile"];
    },
    loading() {
      return this.$store.getters["incidences/loading"] || this.isBusy;
    },
    activeIncidences() {
      return this.$store.getters["incidences/activeIncidences"];
    },
    mappedActiveIncidences() {
      if (!this.activeIncidences.length) return [];
      return this.activeIncidences.map((incidence) => ({
        uuid: incidence.uuid || this.$t("No definit"),
        bookingUuid: incidence.booking?.uuid || null,
        bookingLocalizator:
          incidence.booking?.localizator || this.$t("No definit"),
        bookingSource: incidence.booking?.source || null,
        bookingHighlighted: incidence.booking?.highlighted || false,
        bookingFictitious: incidence.booking?.fictitious || false,
        accommodation:
          incidence.booking?.accommodation?.name || this.$t("No definit"),
        accommodationUuid: incidence.booking?.accommodation?.uuid || null,
        checkinOrder:
          formatDateStringToDatabaseDate(incidence.booking?.checkin) ||
          this.$t("No definit"),
        checkin:
          formatDateStringToDate(incidence.booking?.checkin) ||
          this.$t("No definit"),
        checkoutOrder:
          formatDateStringToDatabaseDate(incidence.booking?.checkout) ||
          this.$t("No definit"),
        checkout:
          formatDateStringToDate(incidence.booking?.checkout) ||
          this.$t("No definit"),
        title: incidence.title || this.$t("No definit"),
        status: incidence.status || this.$t("No definit"),
        createdAtOrder: incidence.createdAt || this.$t("No definit"),
        createdAt: incidence.createdAt,
        lastComment: incidence.lastComment || null,
      }));
    },
  },
  created() {
    this.fetchIncidences();
  },
  methods: {
    fetchIncidences() {
      this.$store
        .dispatch("incidences/fetchIncidences", {
          status: ["OPEN", "PENDING"],
        })
        .catch(() => {
          notifyError(
            this.$t("errors.fetchIncidences.title"),
            this.$t("errors.fetchIncidences.description")
          );
        });
    },
    onRowClicked(item) {
      this.$router.push({
        name: "foravila-incidence-view",
        params: { incidenceUuid: item.uuid },
      });
    },
    onRowMiddleClicked(item) {
      const routeData = this.$router.resolve({
        name: "foravila-incidence-view",
        params: {
          incidenceUuid: item.uuid,
        },
      });
      window.open(routeData.href, "_blank");
    },
    getIncidenceDateTime(date) {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return (
        formatDateStringToDate(date, this.$i18n.locale, formatting) ||
        this.$t("No definit")
      );
    },
  },
};
</script>
