<template>
  <div
    class="incidences-mobile-list-item bg-light cursor-pointer p-2 border rounded"
    @click="onIncidenceSelected"
  >
    <!-- HEADER -->
    <div
      class="mb-50 d-flex"
      :class="{
        'mb-50 border-bottom pb-1': status || showDate,
        'justify-content-between': status,
        'justify-content-end': !status,
      }"
    >
      <b-badge v-if="status" :variant="statusColor">
        {{ statusName }}
      </b-badge>
      <span v-if="showDate">{{ createdDate }}</span>
    </div>

    <!-- CONTENT -->
    <div class="font-weight-bold mb-1">
      {{ title }}
    </div>
    <template v-if="showComment">
      <b-badge v-if="lastCommentIsSolution" class="mb-50">
        {{ $t("Solució") }}
      </b-badge>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-html="lastCommentContent" />
    </template>
    <div class="d-flex justify-content-between text-muted">
      <small class="d-flex justify-content-end">
        <span>
          {{ lastCommentDate }}
        </span>
      </small>
      <small> {{ bookingLocalizator }} @ {{ accommodation }} </small>
    </div>
  </div>
</template>

<script>
import { BBadge } from "bootstrap-vue";
import {
  getBookingIncidenceStatusName,
  getBookingIncidenceStatusColor,
} from "@/utils/methods";
import { formatDateStringToDate } from "@/utils/formatters";

export default {
  components: {
    BBadge,
  },
  props: {
    incidence: {
      type: Object,
      required: true,
    },
    hideDate: {
      type: Boolean,
      default: () => false,
    },
    hideComment: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    showDate() {
      return !this.hideDate;
    },
    showComment() {
      return !this.hideComment;
    },
    bookingLocalizator() {
      return this.incidence?.bookingLocalizator || null;
    },
    accommodation() {
      return this.incidence?.accommodation || null;
    },
    title() {
      return this.incidence?.title || this.$t("Incidència sense títol");
    },
    status() {
      return !!this.incidence?.status || false;
    },
    createdDate() {
      const formatting = {
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return formatDateStringToDate(
        this.incidence?.createdAt,
        this.$i18n.locale,
        formatting
      );
    },
    statusColor() {
      return getBookingIncidenceStatusColor(this.incidence?.status);
    },
    statusName() {
      return getBookingIncidenceStatusName(
        this.incidence?.status,
        this.$i18n.locale
      );
    },
    lastCommentContent() {
      return this.incidence?.lastComment?.content || null;
    },
    lastCommentIsSolution() {
      return this.incidence?.lastComment?.isSolution || false;
    },
    lastCommentDate() {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return formatDateStringToDate(
        this.incidence?.lastComment?.postedAt,
        this.$i18n.locale,
        formatting
      );
    },
  },
  methods: {
    onIncidenceSelected() {
      this.$emit("click", this.incidence);
    },
  },
};
</script>
