<template>
  <div class="incidences-mobile-list">
    <b-row v-if="showSearch || showButton" class="mb-1">
      <b-col v-if="showSearch" cols="12" :class="{ 'mb-50': showButton }">
        <b-form-input
          v-model="filter"
          type="search"
          :placeholder="`${$t('Cerca')}...`"
          autofocus
          :disabled="loading"
        />
      </b-col>
      <b-col v-if="showButton" cols="12">
        <b-button variant="primary" block @click.prevent="onNewButtonClicked">
          {{ $t("Nova incidència") }}
        </b-button>
      </b-col>
    </b-row>

    <!-- INCIDENCES LIST -->
    <b-row>
      <b-col v-if="loading" cols="12" class="d-flex justify-content-center">
        <b-img
          id="loading-gif"
          class="my-5"
          fluid-grow
          :src="require('@/assets/foravila/images/gifs/house-loading-1.gif')"
        />
      </b-col>

      <template v-else>
        <b-col
          v-for="(incidence, index) in filteredIncidences"
          :key="`incidence-${incidence.uuid}`"
          md="12"
        >
          <incidences-mobile-list-item
            :incidence="incidence"
            :hide-comment="hideComments"
            :hide-date="hideDate"
            :class="{ 'mb-1': index < filteredIncidences.length - 1 }"
            @click="onItemClicked"
          />
        </b-col>
      </template>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BButton,
  VBTooltip,
  BImg,
} from "bootstrap-vue";
import IncidencesMobileListItem from "@/views/incidences/list/components/IncidencesMobileListItem.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BButton,
    BImg,
    IncidencesMobileListItem,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    incidences: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    showSearch: {
      type: Boolean,
      default: () => false,
    },
    showButton: {
      type: Boolean,
      default: () => false,
    },
    hideDate: {
      type: Boolean,
      default: () => false,
    },
    hideComments: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      filter: null,
    };
  },
  computed: {
    filteredIncidences() {
      if (!this.filter?.length) return this.incidences;
      const searchTerms = this.filter
        .toLowerCase()
        .normalize("NFD") // convert to Unicode Normalization Form D
        .replace(/[\u0300-\u036f]/g, "") // remove diacritical marks
        .split(" ");

      return this.incidences.filter((incidence) => {
        const normalizedTitle = incidence.title
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        const normalizedComment = incidence.lastComment?.content
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        const normalizedAccommodationName = incidence.accommodation
          ?.toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        return searchTerms.every(
          (term) =>
            normalizedTitle.includes(term) ||
            normalizedComment.includes(term) ||
            normalizedAccommodationName.includes(term)
        );
      });
    },
  },
  methods: {
    onItemClicked(incidence) {
      this.$router.push({
        name: "foravila-incidence-view",
        params: { incidenceUuid: incidence.uuid },
      });
    },
    onNewButtonClicked() {
      this.$router.push({
        name: "foravila-new-incidence",
      });
    },
  },
};
</script>
