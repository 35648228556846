<template>
  <b-table
    striped
    borderless
    responsive
    class="position-relative"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    sort-icon-left
    :items="items"
    :fields="fields"
    show-empty
    :filter="filter"
    :empty-text="$t('errors.emptyTable.title')"
    :empty-filtered-text="$t('errors.emptyTable.title')"
  >
    <template #table-busy>
      <div class="text-center text-primary my-2">
        <b-spinner class="align-middle mr-2" />
        <strong>{{ $t("Carregant") }}...</strong>
      </div>
    </template>

    <!-- Column: localizator -->
    <template #cell(localizator)="data">
      <b-booking-localizator
        :localizator="data.value"
        :source="data.item.source"
        :highlighted="data.item.highlighted"
        :fictitious="data.item.fictitious"
      />
    </template>

    <!-- Column: checkout -->
    <template #cell(checkoutOrder)="data">
      <div class="text-nowrap mb-25">
        {{ data.item.accommodation }}
      </div>
      <small class="d-flex align-items-center flex-nowrap mb-25">
        {{ data.item.checkin }}
        <feather-icon icon="ArrowRightIcon" size="10" class="mx-25" />
        {{ data.item.checkout }}
      </small>
      <small class="text-nowrap">
        <i>
          {{ data.item.client }}
        </i>
      </small>
    </template>

    <template #cell(initialContact)="data">
      <div @click.stop="updateInitialStats(data.item.statsUuid, !data.value)">
        <feather-icon
          size="25"
          v-if="data.value"
          icon="CheckIcon"
          class="text-success"
          v-b-tooltip.hover.top="'Click per desmarcar'"
        />
        <feather-icon
          size="25"
          v-else
          icon="SlashIcon"
          class="text-danger"
          v-b-tooltip.hover.top="'Click per marcar com a fet'"
        />
        <div v-if="data.value && data.item.initialContactDate">
          <small>{{ data.item.initialContactDate }}</small>
        </div>
      </div>
    </template>

    <template #cell(halfStayContact)="data">
      <div @click.stop="updateHalfStayStats(data.item.statsUuid, !data.value)">
        <feather-icon
          size="25"
          v-if="data.value"
          icon="CheckIcon"
          class="text-success"
          v-b-tooltip.hover.top="'Click per desmarcar'"
        />
        <feather-icon
          size="25"
          v-else
          icon="SlashIcon"
          class="text-danger"
          v-b-tooltip.hover.top="'Click per marcar com a fet'"
        />
        <div v-if="data.value && data.item.halfStayContactDate">
          <small>{{ data.item.halfStayContactDate }}</small>
        </div>
      </div>
    </template>

    <template #cell(lastContact)="data">
      <div @click.stop="updateLastStats(data.item.statsUuid, !data.value)">
        <feather-icon
          size="25"
          v-if="data.value"
          icon="CheckIcon"
          class="text-success"
          v-b-tooltip.hover.top="'Click per desmarcar'"
        />
        <feather-icon
          size="25"
          v-else
          icon="SlashIcon"
          class="text-danger"
          v-b-tooltip.hover.top="'Click per marcar com a fet'"
        />
        <div v-if="data.value && data.item.lastContactDate">
          <small>{{ data.item.lastContactDate }}</small>
        </div>
      </div>
    </template>

    <template #cell(bedLinnenChange)="data">
      <div
        v-if="hasBedLinnenChange(data.item.checkinDate, data.item.checkoutDate)"
      >
        {{
          formatDateObjectToDate(
            getBedLinnenChangeDate(
              data.item.checkinDate,
              data.item.checkoutDate
            )
          )
        }}
      </div>
      <b-badge v-else>Sense canvi</b-badge>
    </template>
  </b-table>
</template>

<script>
import { BTable, BSpinner, VBTooltip, BBadge } from "bootstrap-vue";
import {
  formatDateObjectToDatabaseDateTime,
  formatDateObjectToDate,
} from "@/utils/formatters";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";
import { dateDiff } from "@/utils/methods";

export default {
  components: {
    BTable,
    BSpinner,
    BBookingLocalizator,
    BBadge,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: String,
      required: false,
      default: null,
    },
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      isBusy: false,
      sortBy: "checkoutOrder",
      sortDesc: false,
      fields: [
        {
          key: "localizator",
          label: this.$t("Reserva"),
          thStyle: { width: "180px" },
        },
        { key: "checkoutOrder", label: this.$t("Estada"), sortable: true },
        {
          key: "initialContact",
          label: this.$t("Contacte inicial"),
          sortable: true,
        },
        {
          key: "halfStayContact",
          label: this.$t("Contacte durant estada"),
          sortable: true,
        },
        {
          key: "bedLinnenChange",
          label: this.$t("Canvi de roba"),
          sortable: true,
        },
        {
          key: "lastContact",
          label: this.$t("Contacte final"),
          sortable: true,
        },
      ],
      formatDateObjectToDate,
    };
  },
  methods: {
    updateInitialStats(statsUuid, newValue) {
      this.isBusy = true;
      this.$store
        .dispatch("booking/updateStats", {
          uuid: statsUuid,
          clientInitialContactSent: newValue,
          clientInitialContactSentAt: newValue
            ? formatDateObjectToDatabaseDateTime(new Date())
            : null,
        })
        .finally(() => this.$emit("updated"));
    },
    updateHalfStayStats(statsUuid, newValue) {
      this.isBusy = true;
      this.$store
        .dispatch("booking/updateStats", {
          uuid: statsUuid,
          clientHalfStayContactSent: newValue,
          clientHalfStayContactSentAt: newValue
            ? formatDateObjectToDatabaseDateTime(new Date())
            : null,
        })
        .finally(() => this.$emit("updated"));
    },
    updateLastStats(statsUuid, newValue) {
      this.isBusy = true;
      this.$store
        .dispatch("booking/updateStats", {
          uuid: statsUuid,
          clientLastContactSent: newValue,
          clientLastContactSentAt: newValue
            ? formatDateObjectToDatabaseDateTime(new Date())
            : null,
        })
        .finally(() => this.$emit("updated"));
    },
    hasBedLinnenChange(checkin, checkout) {
      return dateDiff(checkin, checkout) > 8;
    },
    getBedLinnenChangeDate(checkin, checkout) {
      const checkinDate = new Date(checkin);
      const nights = dateDiff(checkin, checkout);

      let bedLinnenChangeDate = new Date(checkinDate);

      if (nights <= 8) {
        bedLinnenChangeDate = null;
      } else if (nights < 14) {
        bedLinnenChangeDate.setDate(
          checkinDate.getDate() + Math.ceil(nights / 2)
        );
      } else {
        bedLinnenChangeDate.setDate(checkinDate.getDate() + 7);
      }

      return bedLinnenChangeDate;
    },
  },
};
</script>
