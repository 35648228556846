<template>
  <b-overlay :show="isBusy" rounded="sm" variant="white">
    <b-card>
      <b-card-title>
        <b-badge
          v-if="mappedRequestedBookings.length"
          variant="primary"
          class="mr-25"
        >
          {{ mappedRequestedBookings.length }}
        </b-badge>
        <span>
          {{
            mappedRequestedBookings.length === 1
              ? "Reserva pendent"
              : "Reserves pendents"
          }}
          de confirmar
        </span>
      </b-card-title>

      <b-table
        v-if="mappedRequestedBookings.length || isBusy"
        striped
        borderless
        responsive
        class="position-relative"
        :busy.sync="isBusy"
        :per-page="itemsPerPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        sort-icon-left
        :items="mappedRequestedBookings"
        :fields="fields"
        show-empty
        :empty-text="$t('errors.emptyTable.title')"
        :empty-filtered-text="$t('errors.emptyTable.title')"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle mr-2" />
            <strong>{{ $t("Carregant") }}...</strong>
          </div>
        </template>

        <!-- Column: date -->
        <template #cell(date)="data">
          <span class="text-nowrap">
            {{ bookingDate(data.item) }}
          </span>
        </template>

        <!-- Column: localizator -->
        <template #cell(localizator)="data">
          <b-booking-localizator
            :localizator="data.value"
            :source="data.item.source"
            :highlighted="data.item.highlighted"
            :fictitious="data.item.fictitious"
          />
        </template>

        <!-- Column: accommodation -->
        <template #cell(accommodation)="data">
          <b-accommodation
            :uuid="data.item.accommodationUuid"
            :name="data.value"
          />
        </template>

        <!-- Column: checkin -->
        <template #cell(checkin)="data">
          {{ bookingFormattedDate(data.item.checkin) }}
        </template>

        <!-- Column: checkout -->
        <template #cell(checkout)="data">
          {{ bookingFormattedDate(data.item.checkout) }}
        </template>
      </b-table>

      <div v-else class="text-center">
        <h5 class="py-2">Tot al dia! Bona feina 👏</h5>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BTable,
  BSpinner,
  BCardTitle,
  BBadge,
} from "bootstrap-vue";
import { notifyError } from "@/utils/methods";
import { formatDateObjectToDate } from "@/utils/formatters";
import BBookingLocalizator from "@foravila-core/components/b-booking-localizator/BBookingLocalizator.vue";
import BAccommodation from "@foravila-core/components/b-accommodation/BAccommodation.vue";

export default {
  components: {
    BOverlay,
    BCard,
    BTable,
    BSpinner,
    BBookingLocalizator,
    BAccommodation,
    BCardTitle,
    BBadge,
  },
  data() {
    return {
      isBusy: false,
      sortBy: "date",
      sortDesc: true,
      itemsPerPage: 1000,
      fields: [
        {
          key: "date",
          label: this.$t("Data"),
          sortable: true,
          thStyle: { width: "160px" },
        },
        {
          key: "localizator",
          label: this.$t("Reserva"),
          thStyle: { width: "180px" },
        },
        { key: "accommodation", label: this.$t("Allotjament") },
        { key: "checkin", label: this.$t("Entrada"), sortable: true },
        { key: "checkout", label: this.$t("Sortida"), sortable: true },
      ],
      selectedBooking: null,
    };
  },
  computed: {
    requestedBookings() {
      return this.$store.getters["bookings/requestedBookings"];
    },
    mappedRequestedBookings() {
      if (!this.requestedBookings.length) return [];
      return this.requestedBookings.map((booking) => ({
        uuid: booking.uuid || this.$t("No definit"),
        date: new Date(booking.date) || this.$t("No definit"),
        localizator: booking.localizator || this.$t("No definit"),
        source: booking.source || this.$t("No definit"),
        accommodation: booking.accommodation?.name || this.$t("No definit"),
        accommodationUuid: booking.accommodation?.uuid || null,
        checkin: new Date(booking.checkin) || this.$t("No definit"),
        checkout: new Date(booking.checkout) || this.$t("No definit"),
        highlighted: booking.highlighted || false,
        fictitious: booking.fictitious || false,
      }));
    },
  },
  created() {
    this.fetchRequestedBookings();
  },
  methods: {
    fetchRequestedBookings() {
      this.isBusy = true;
      this.$store
        .dispatch("bookings/fetchRequestedBookings")
        .catch(() =>
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.description")
          )
        )
        .finally(() => {
          this.isBusy = false;
        });
    },
    bookingDate(booking) {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return (
        formatDateObjectToDate(booking.date, this.$i18n.locale, formatting) ||
        "No definit"
      );
    },
    bookingFormattedDate(date) {
      return formatDateObjectToDate(date) || "No definit";
    },
  },
};
</script>
