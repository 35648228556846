<template>
  <div>
    <b-row>
      <b-col cols="12">
        <greeting-card />
      </b-col>
    </b-row>

    <b-row v-if="isAdmin">
      <b-col cols="12" lg="7" xl="8">
        <current-incidences-card />
        <requested-bookings-card />
      </b-col>
      <b-col cols="12" lg="5" xl="4">
        <not-fully-paid-current-bookings-card />
        <current-bookings-card />
      </b-col>
    </b-row>

    <b-row v-if="isManager">
      <b-col cols="12" md="8">
        <current-incidences-card />
      </b-col>
      <b-col cols="12" md="4">
        <not-fully-paid-current-bookings-card />
      </b-col>
      <b-col cols="12">
        <current-bookings-with-contacts-card />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import GreetingCard from "@/views/home/components/GreetingCard.vue";
import RequestedBookingsCard from "@/views/home/components/RequestedBookingsCard.vue";
import CurrentBookingsCard from "@/views/home/components/CurrentBookingsCard.vue";
import NotFullyPaidCurrentBookingsCard from "@/views/home/components/NotFullyPaidCurrentBookingsCard.vue";
import CurrentBookingsWithContactsCard from "@/views/home/components/CurrentBookingsWithContactsCard.vue";
import CurrentIncidencesCard from "@/views/home/components/CurrentIncidencesCard.vue";

export default {
  components: {
    BRow,
    BCol,
    GreetingCard,
    RequestedBookingsCard,
    CurrentBookingsCard,
    NotFullyPaidCurrentBookingsCard,
    CurrentBookingsWithContactsCard,
    CurrentIncidencesCard,
  },
  computed: {
    isAdmin() {
      return this.$store.getters["auth/isAdmin"];
    },
    isManager() {
      return this.$store.getters["auth/isManager"];
    },
  },
};
</script>
