<template>
  <b-overlay :show="isBusy" rounded="sm" variant="white" opacity="0.9">
    <b-card>
      <b-row class="mb-2">
        <b-col cols="12">
          <b-card-title class="text-nowrap mb-0">
            <span>Reserves en curs</span>
            <b-badge
              v-if="currentBookings.length"
              variant="primary"
              class="ml-50"
            >
              {{ currentBookings.length }}
            </b-badge>
          </b-card-title>
        </b-col>
      </b-row>

      <b-row class="mb-2 d-flex justify-content-end">
        <b-col v-if="currentBookings.length" cols="12" md="auto">
          <b-form-input
            v-model="filter"
            type="search"
            :placeholder="`${$t('Cerca')}...`"
            debounce="1000"
            :autofocus="!isMobile"
            :disabled="isBusy"
            class="w-full"
          />
        </b-col>
      </b-row>

      <template v-if="currentBookings.length || isBusy">
        <!-- <current-bookings-with-contacts-mobile-list
          v-if="isMobile"
          :filter="filter"
          :items="mappedCurrentBookings"
          @updated="fetchCurrentBookings"
        /> -->
        <current-bookings-with-contacts-table
          :filter="filter"
          :items="mappedCurrentBookings"
          @updated="fetchCurrentBookings"
        />
      </template>

      <div v-else class="text-center">
        <h5 class="py-2">Tohom és a casa seva, take it easy 😎 ✌️</h5>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BCardTitle,
  BBadge,
  BFormInput,
} from "bootstrap-vue";
import { notifyError } from "@/utils/methods";
import {
  formatDateObjectToDatabaseDate,
  formatDateStringToDatabaseDate,
  formatDateStringToDate,
} from "@/utils/formatters";
import CurrentBookingsWithContactsTable from "@/views/home/components/CurrentBookingsWithContactsTable.vue";
// import CurrentBookingsWithContactsMobileList from "@/views/home/components/CurrentBookingsWithContactsMobileList.vue";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BCardTitle,
    BBadge,
    BFormInput,
    CurrentBookingsWithContactsTable,
    // CurrentBookingsWithContactsMobileList,
  },
  data() {
    return {
      isBusy: false,
      filter: null,
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters["app/isMobile"];
    },
    currentBookings() {
      return this.$store.getters["bookings/currentBookings"];
    },
    mappedCurrentBookings() {
      if (!this.currentBookings.length) return [];
      return this.currentBookings.map((booking) => {
        let rowVariant = null;
        if (this.isSameAsToday(booking.checkout)) rowVariant = "danger";
        if (this.isSameAsToday(booking.checkin)) rowVariant = "primary";

        return {
          uuid: booking.uuid || this.$t("No definit"),
          date: new Date(booking.date) || this.$t("No definit"),
          localizator: booking.localizator || this.$t("No definit"),
          source: booking.source || this.$t("No definit"),
          accommodation: booking.accommodation?.name || this.$t("No definit"),
          client: booking.client?.fullName || this.$t("No definit"),
          checkinDate: booking.checkin || null,
          checkinOrder: booking.checkin || this.$t("No definit"),
          checkin:
            formatDateStringToDate(booking.checkin) || this.$t("No definit"),
          checkoutDate: booking.checkout || null,
          checkoutOrder: booking.checkout || this.$t("No definit"),
          checkout:
            formatDateStringToDate(booking.checkout) || this.$t("No definit"),
          highlighted: booking.highlighted || false,
          statsUuid: booking.stats?.uuid || null,
          initialContact: booking.stats?.clientInitialContactSent || false,
          initialContactDate:
            this.formatContactDate(booking.stats?.clientInitialContactSentAt) ||
            null,
          halfStayContact: booking.stats?.clientHalfStayContactSent || false,
          halfStayContactDate:
            this.formatContactDate(
              booking.stats?.clientHalfStayContactSentAt
            ) || null,
          lastContact: booking.stats?.clientLastContactSent || false,
          lastContactDate:
            this.formatContactDate(booking.stats?.clientLastContactSentAt) ||
            null,
          _rowVariant: rowVariant,
        };
      });
    },
  },
  created() {
    if (!this.currentBookings?.length) this.fetchCurrentBookings();
  },
  methods: {
    fetchCurrentBookings() {
      this.isBusy = true;
      this.$store
        .dispatch("bookings/fetchCurrentBookings")
        .catch(() =>
          notifyError(
            this.$t("errors.fetchBookings.title"),
            this.$t("errors.fetchBookings.description")
          )
        )
        .finally(() => {
          this.isBusy = false;
        });
    },
    isSameAsToday(dateString) {
      const date = formatDateStringToDatabaseDate(dateString);
      const today = formatDateObjectToDatabaseDate(new Date());
      return date === today;
    },
    formatContactDate(date) {
      const formatting = {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Europe/Madrid",
      };
      return formatDateStringToDate(date, this.$i18n.locale, formatting);
    },
  },
};
</script>
